import React, { createRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import FocusLock from "react-focus-lock";
import { KeyBindingAction } from "matrix-react-sdk/src/accessibility/KeyboardShortcuts";
import AccessibleTooltipButton from "matrix-react-sdk/src/components/views/elements/AccessibleTooltipButton";
import { getKeyBindingsManager } from "matrix-react-sdk/src/KeyBindingsManager";
// import { _t } from "matrix-react-sdk/src/languageHandler";
import { PasswordResponses, PDFDocumentProxy } from "pdfjs-dist";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "core-js/proposals/promise-with-resolvers.js";

import { _t } from "../../../../languageHandler";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/legacy/build/pdf.worker.min.mjs", import.meta.url).toString();

const options = {
    cMapUrl: "/cmaps/",
    standardFontDataUrl: "/standard_fonts/",
};

const maxWidth = 700;

type PDFFile = File | null;

interface IProps {
    file?: File;
    src?: string;
    name?: string;
    description?: string;
    isNotAllowDownload?: boolean;
    isPreviewFileModal?: boolean;

    onFinished(): void;
}

interface IState {
    file: PDFFile;
    numPages: number;
}

export default class PDFView extends React.Component<IProps, IState> {
    public constructor(props: IProps) {
        super(props);
        this.state = {
            file: this.props.file!,
            numPages: 1,
        };
    }

    private focusLock = createRef<any>();

    private onKeyDown = (ev: KeyboardEvent): void => {
        const action = getKeyBindingsManager().getAccessibilityAction(ev);
        switch (action) {
            case KeyBindingAction.Escape:
                ev.stopPropagation();
                ev.preventDefault();
                this.props.onFinished();
                break;
        }
    };

    private onDownloadClick = (): void => {
        const a = document.createElement("a");
        a.href = this.props.src!;
        if (this.state.file?.name) a.download = this.state.file.name;
        a.target = "_blank";
        a.rel = "noreferrer noopener";
        a.click();
    };

    private onDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy): void => {
        const pages = { numPages: nextNumPages };
        this.setState({ numPages: pages.numPages });
    };

    private onPassword = (callback: (password: string | null) => void, reason: number): void => {
        const callbackProxy = (password: string | null): void => {
            // Cancel button handler
            if (password == null) {
                this.props.onFinished();
                return;
            }

            callback(password);
        };

        switch (reason) {
            case PasswordResponses.NEED_PASSWORD: {
                const password = prompt(_t("ctalk|pdf_view|pw_dialog_description"));
                callbackProxy(password);
                break;
            }
            case PasswordResponses.INCORRECT_PASSWORD: {
                const password = prompt(_t("ctalk|pdf_view|pw_incorrect_description"));
                callbackProxy(password);
                break;
            }
            default:
        }
    };

    public render(): React.ReactNode {
        return (
            <FocusLock
                returnFocus={true}
                lockProps={{
                    "onKeyDown": this.onKeyDown,
                    "role": "dialog",
                    "aria-label": _t("lightbox|title"),
                }}
                className="mx_ImageView"
                ref={this.focusLock}
            >
                <div className="mx_ImageView_panel">
                    <div className="mx_ImageView_toolbar">
                        {!this.props.isNotAllowDownload && (
                            <AccessibleTooltipButton
                                className="mx_ImageView_button mx_ImageView_button_download"
                                title={_t("action|download")}
                                onClick={this.onDownloadClick}
                            />
                        )}
                        <AccessibleTooltipButton
                            className="mx_ImageView_button mx_ImageView_button_close"
                            title={_t("action|close")}
                            onClick={this.props.onFinished}
                        />
                    </div>
                </div>
                <div
                    className="mx_ImageView_image_wrapper"
                    onMouseDown={this.props.onFinished}
                    role="button"
                    tabIndex={-1}
                >
                    <div className="Pdf_view__container__document">
                        <Document
                            file={this.state.file}
                            onLoadSuccess={this.onDocumentLoadSuccess}
                            options={options}
                            onMouseDown={(e): void => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            onPassword={this.onPassword}
                        >
                            {Array.from(new Array(this.state.numPages), (el, index) => (
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} width={maxWidth} />
                            ))}
                        </Document>
                    </div>
                </div>
                {this.props.description && <div className="ctalk_ImageView_description">{this.props.description}</div>}
            </FocusLock>
        );
    }
}

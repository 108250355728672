import axios, { AxiosError, AxiosResponse, AxiosRequestHeaders } from "axios";
import * as Lifecycle from "matrix-react-sdk/src/Lifecycle";
import SdkConfig from "matrix-react-sdk/src/SdkConfig";

export class HttpService {
    private authUrl: string;

    public constructor() {
        this.authUrl = SdkConfig.get<any>('auth_server')['base_url'];
    }

    public setAuthUrl(url: string): void {
        this.authUrl = url;
    }

    private async getAccessToken(): Promise<string> {
        const storedSession = await Lifecycle.getStoredSessionVars();
        return storedSession.accessToken as string;
    }

    private async prepareHeaders(): Promise<AxiosRequestHeaders> {
        const headers: AxiosRequestHeaders = {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "application/json",
        }
        const accessToken = await this.getAccessToken();
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`;
        }

        return headers;
    }

    /**
     * If provide absolute URL, don't add Auth URL to prefix
     *
     * @param urlOrPath
     * @private
     */
    private prepareUrl(urlOrPath: string): string {
        if (urlOrPath.startsWith('http') ) {
            return urlOrPath;
        }
        return `${this.authUrl}/${urlOrPath}`;
    }

    /**
     * Request GET method to url
     *
     * @param url string
     */
    public async get<T>(url: string): Promise<T> {
        try {
            const headers = await this.prepareHeaders();
            const result = await axios.get(this.prepareUrl(url), {
                headers: headers,
            });
            return result.data;
        } catch (e: any) {
            throw parseErrorResponse(e);
        }
    }

    /**
     * Request POST method to URL with body
     *
     * @param url string
     * @param body any
     */
    public async post<T>(url: string, body: any): Promise<AxiosResponse<T>> {
        try {
            const headers = await this.prepareHeaders();
            return await axios.post(this.prepareUrl(url), body, {
                headers: headers,
            });
        } catch (e: any) {
            throw parseErrorResponse(e);
        }
    }

    /**
     * Request PUT method to URL with param id
     *
     * @param uri string
     * @param id string
     * @param body any
     */
    public async put<T>(uri: string, id: string | undefined, body: any): Promise<AxiosResponse<T>> {
        try {
            let url = this.prepareUrl(uri);
            if (id) {
                url = `${url}/${id}`;
            }
            const headers = await this.prepareHeaders();
            return await axios.put(url, body, {
                headers: headers,
            });
        } catch (e: any) {
            throw parseErrorResponse(e);
        }
    }

    /**
     * Request DELETE method to URL with param id
     *
     * @param uri string
     * @param id string
     */
    public async delete<T>(uri: string, id: string): Promise<AxiosResponse<T>> {
        try {
            let url = this.prepareUrl(uri);
            if (id) {
                url = `${url}/${id}`;
            }
            const headers = await this.prepareHeaders();
            return await axios.delete(url, {
                headers: headers,
            });
        } catch (e: any) {
            throw parseErrorResponse(e);
        }
    }
}

export function parseErrorResponse(e: AxiosError): any {
    return {
        ...JSON.parse(e.request?.response || '{}'),
        code: e.code,
        message: e.message,
        status: e.request?.status,
    };
}


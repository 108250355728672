import { ViewRoomPayload as MatrixViewRoomPayload } from "matrix-react-sdk/src/dispatcher/payloads/ViewRoomPayload";
import { MatrixEvent } from "matrix-js-sdk/src/matrix";
import { Member } from "../../utils/direct-messages";

export type ViewRoomPayload = MatrixViewRoomPayload & {
    editingToEvent?: MatrixEvent;
    roomType?: RoomDMType;
    targets: Member[];
};

export enum RoomDMType {
    VIEW_ROOM = "view_room",
    VIEW_CHECK_ROOM_FAILED = "view_check_room_failed",
}

export enum MetricsTrigger {
    Created = "Created",
    RoomList = "RoomList",
    RoomDirectory = "RoomDirectory",
    SpaceHierarchy = "SpaceHierarchy",
    Timeline = "Timeline",
    Notification = "Notification",
    Tombstone = "Tombstone",
    Predecessor = "Predecessor",
    Widget = "Widget",
    MessageSearch = "MessageSearch",
    MessageUser = "MessageUser",
    VerificationRequest = "VerificationRequest",
    SlashCommand = "SlashCommand",
    Shortcut = "Shortcut",
    WebNotificationPanel = "WebNotificationPanel",
    WebKeyboardShortcut = "WebKeyboardShortcut",
    WebHorizontalBreadcrumbs = "WebHorizontalBreadcrumbs",
    WebVerticalBreadcrumbs = "WebVerticalBreadcrumbs",
    WebAcceptCall = "WebAcceptCall",
    WebDialPad = "WebDialPad",
    WebForwardShortcut = "WebForwardShortcut",
    WebUnifiedSearch = "WebUnifiedSearch",
    WebPredecessorSettings = "WebPredecessorSettings",
    WebSpaceContextSwitch = "WebSpaceContextSwitch",
    WebRoomListNotificationBadge = "WebRoomListNotificationBadge",
    WebSpacePanelNotificationBadge = "WebSpacePanelNotificationBadge",
    WebFloatingCallWindow = "WebFloatingCallWindow",
    MobileFileSearch = "MobileFileSearch",
    MobileRoomSearch = "MobileRoomSearch",
    MobileSearchContactDetail = "MobileSearchContactDetail",
    MobileRoomMemberDetail = "MobileRoomMemberDetail",
    MobileSpaceMemberDetail = "MobileSpaceMemberDetail",
    MobileInCall = "MobileInCall",
    MobileSpaceMenu = "MobileSpaceMenu",
    MobileSpaceSettings = "MobileSpaceSettings",
    MobileRoomPreview = "MobileRoomPreview",
    MobilePermalink = "MobilePermalink",
    MobileLinkShare = "MobileLinkShare",
    MobileExploreRooms = "MobileExploreRooms",
    MobileSpaceMembers = "MobileSpaceMembers",
    MobileSpaceBottomSheet = "MobileSpaceBottomSheet",
}

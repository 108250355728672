/*
Copyright 2018-2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import { debounce } from "lodash";
// import classNames from "classnames";
import React, { ChangeEvent, FormEvent } from "react";
import { ISecretStorageKeyInfo } from "matrix-js-sdk/src/crypto/api";
import { logger } from "matrix-js-sdk/src/logger";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";
import Field from "matrix-react-sdk/src/components/views/elements/Field";
import AccessibleButton, { ButtonEvent } from "matrix-react-sdk/src/components/views/elements/AccessibleButton";
import { _t } from "matrix-react-sdk/src/languageHandler";
import { accessSecretStorage } from "matrix-react-sdk/src/SecurityManager";
import Modal from "matrix-react-sdk/src/Modal";
import InteractiveAuthDialog from "matrix-react-sdk/src/components/views/dialogs/InteractiveAuthDialog";
import DialogButtons from "matrix-react-sdk/src/components/views/elements/DialogButtons";
import BaseDialog from "matrix-react-sdk/src/components/views/dialogs/BaseDialog";
import { chromeFileInputFix } from "matrix-react-sdk/src/utils/BrowserWorkarounds";
// CTalk imported
import SdkConfig from "matrix-react-sdk/src/SdkConfig";
import StyledRadioButton from "matrix-react-sdk/src/components/views/elements/StyledRadioButton";
import withValidation, { IValidationResult } from "matrix-react-sdk/src/components/views/elements/Validation";
import { Spinner } from "@matrix-org/react-sdk-module-api/lib/components/Spinner";
import * as CloudKitService from "@ctalk/cloudkit/CloudKitService";
import WaitingLoginDialog from "@ctalk/views/dialogs/WaitingLoginDialog";
import { ICloudKitError } from "@ctalk/interfaces/ICloudKit";

// Maximum acceptable size of a key file. It's 59 characters including the spaces we encode,
// so this should be plenty and allow for people putting extra whitespace in the file because
// maybe that's a thing people would do?
const KEY_FILE_MAX_SIZE = 128;

// Don't shout at the user that their key is invalid every time they type a key: wait a short time
const VALIDATION_THROTTLE_MS = 200;

export type KeyParams = { passphrase?: string; recoveryKey?: string };

interface IProps {
    keyInfo: ISecretStorageKeyInfo;
    checkPrivateKey: (k: KeyParams) => Promise<boolean>;
    onFinished(result?: false | KeyParams): void;
}

interface IState {
    recoveryKey: string;
    recoveryKeyValid: boolean | null;
    recoveryKeyCorrect: boolean | null;
    recoveryKeyFileError: boolean | null;
    forceRecoveryKey: boolean;
    passPhrase: string;
    keyMatches: boolean | null;
    resetting: boolean;
    // CTalk added
    loginType: ELoginType;
    isAppleLoggedIn: boolean;
    syncCloudKitError: boolean;
    loading: boolean;
    passCode: string;
    textErrorCloudKit: string;
    keyEncrypted: string;
}

// CTalk added
enum ELoginType {
    CLOUDKIT = "CLOUDKIT",
    SECURITY_KEY = "SECURITY_KEY",
}
// CTalk added
enum InputField {
    PassCode = "input_field_pass_code",
}

/*
 * Access Secure Secret Storage by requesting the user's passphrase.
 */
export default class AccessSecretStorageDialog extends React.PureComponent<IProps, IState> {
    private fileUpload = React.createRef<HTMLInputElement>();
    private inputRef = React.createRef<HTMLInputElement>();
    // CTalk added
    protected waitingLoginModal: any;
    private cloudKitConfig: CloudKitService.CloudKitConfig;

    public constructor(props: IProps) {
        super(props);

        this.state = {
            recoveryKey: "",
            recoveryKeyValid: null,
            recoveryKeyCorrect: null,
            recoveryKeyFileError: null,
            forceRecoveryKey: false,
            passPhrase: "",
            keyMatches: null,
            resetting: false,
            // CTalk added
            loginType: ELoginType.SECURITY_KEY,
            isAppleLoggedIn: false,
            syncCloudKitError: false,
            loading: false,
            passCode: '',
            textErrorCloudKit: '',
            keyEncrypted: '',
        };
        // CTalk added
        this.cloudKitConfig = SdkConfig.get<any>("cloud_storage");
    }

    private onCancel = (): void => {
        if (this.state.resetting) {
            this.setState({ resetting: false });
        }
        this.props.onFinished(false);
    };

    private onUseRecoveryKeyClick = (): void => {
        this.setState({
            forceRecoveryKey: true,
        });
    };

    private validateRecoveryKeyOnChange = debounce(async (): Promise<void> => {
        await this.validateRecoveryKey();
    }, VALIDATION_THROTTLE_MS);

    private async validateRecoveryKey(): Promise<void> {
        if (this.state.recoveryKey === "") {
            this.setState({
                recoveryKeyValid: null,
                recoveryKeyCorrect: null,
            });
            return;
        }

        try {
            const cli = MatrixClientPeg.safeGet();
            const decodedKey = cli.keyBackupKeyFromRecoveryKey(this.state.recoveryKey);
            const correct = await cli.checkSecretStorageKey(decodedKey, this.props.keyInfo);
            this.setState({
                recoveryKeyValid: true,
                recoveryKeyCorrect: correct,
            });
        } catch (e) {
            this.setState({
                recoveryKeyValid: false,
                recoveryKeyCorrect: false,
            });
        }
    }

    private onRecoveryKeyChange = (ev: ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            recoveryKey: ev.target.value,
            recoveryKeyFileError: null,
        });

        // also clear the file upload control so that the user can upload the same file
        // the did before (otherwise the onchange wouldn't fire)
        if (this.fileUpload.current) this.fileUpload.current.value = "";

        // We don't use Field's validation here because a) we want it in a separate place rather
        // than in a tooltip and b) we want it to display feedback based on the uploaded file
        // as well as the text box. Ideally we would refactor Field's validation logic so we could
        // re-use some of it.
        this.validateRecoveryKeyOnChange();
    };

    private onRecoveryKeyFileChange = async (ev: ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (!ev.target.files?.length) return;

        const f = ev.target.files[0];

        if (f.size > KEY_FILE_MAX_SIZE) {
            this.setState({
                recoveryKeyFileError: true,
                recoveryKeyCorrect: false,
                recoveryKeyValid: false,
            });
        } else {
            const contents = await f.text();
            // test it's within the base58 alphabet. We could be more strict here, eg. require the
            // right number of characters, but it's really just to make sure that what we're reading is
            // text because we'll put it in the text field.
            if (/^[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz\s]+$/.test(contents)) {
                this.setState({
                    recoveryKeyFileError: null,
                    recoveryKey: contents.trim(),
                });
                await this.validateRecoveryKey();
            } else {
                this.setState({
                    recoveryKeyFileError: true,
                    recoveryKeyCorrect: false,
                    recoveryKeyValid: false,
                    recoveryKey: "",
                });
            }
        }
    };

    private onRecoveryKeyFileUploadClick = (): void => {
        this.fileUpload.current?.click();
    };

    private onPassPhraseNext = async (ev: FormEvent<HTMLFormElement> | React.MouseEvent): Promise<void> => {
        ev.preventDefault();

        if (this.state.passPhrase.length <= 0) {
            this.inputRef.current?.focus();
            return;
        }

        this.setState({ keyMatches: null });
        const input = { passphrase: this.state.passPhrase };
        const keyMatches = await this.props.checkPrivateKey(input);
        if (keyMatches) {
            this.props.onFinished(input);
        } else {
            this.setState({ keyMatches });
            this.inputRef.current?.focus();
        }
    };

    /**
     * CTalk added
     * @private
     */
    private async verifyKeyFromCloudKit(): Promise<void> {
        try {
            const cli = MatrixClientPeg.safeGet();
            const userId = cli.getSafeUserId();
            this.setState({
                loading: true,
            });
            const recoveryKey = await CloudKitService.decryptKey(
                this.state.keyEncrypted,
                userId + this.state.passCode,
            );
            const input = {
                recoveryKey: recoveryKey
            };
            const keyMatches = await this.props.checkPrivateKey(input);
            if (keyMatches) {
                this.props.onFinished(input);
            } else {
                this.setState({ keyMatches });
            }
        } catch (e) {
            this.setState({
                loading: false,
                textErrorCloudKit: _t(
                    "CTALK_PASS_CODE_INCORRECT",
                    {},
                    {
                        b: (sub) => <b>{sub}</b>,
                    },
                ) as string,
            });
        }
    }

    private onRecoveryKeyNext = async (ev: FormEvent<HTMLFormElement> | React.MouseEvent): Promise<void> => {
        ev.preventDefault();
        // CTalk added
        if (this.state.loginType === ELoginType.CLOUDKIT) {
            await this.verifyKeyFromCloudKit();
            return;
        }
        if (!this.state.recoveryKeyValid) return;

        this.setState({ keyMatches: null });
        const input = { recoveryKey: this.state.recoveryKey };
        const keyMatches = await this.props.checkPrivateKey(input);
        if (keyMatches) {
            this.props.onFinished(input);
        } else {
            this.setState({ keyMatches });
        }
    };

    private onPassPhraseChange = (ev: ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            passPhrase: ev.target.value,
            keyMatches: null,
        });
    };

    private onResetAllClick = (ev: ButtonEvent): void => {
        ev.preventDefault();
        this.setState({ resetting: true });
    };

    private onConfirmResetAllClick = async (): Promise<void> => {
        // Hide ourselves so the user can interact with the reset dialogs.
        // We don't conclude the promise chain (onFinished) yet to avoid confusing
        // any upstream code flows.
        //
        // Note: this will unmount us, so don't call `setState` or anything in the
        // rest of this function.
        Modal.toggleCurrentDialogVisibility();

        try {
            // Force reset secret storage (which resets the key backup)
            await accessSecretStorage(async (): Promise<void> => {
                // Now reset cross-signing so everything Just Works™ again.
                const cli = MatrixClientPeg.safeGet();
                await cli.bootstrapCrossSigning({
                    authUploadDeviceSigningKeys: async (makeRequest): Promise<void> => {
                        const { finished } = Modal.createDialog(InteractiveAuthDialog, {
                            title: _t("encryption|bootstrap_title"),
                            matrixClient: cli,
                            makeRequest,
                        });
                        const [confirmed] = await finished;
                        if (!confirmed) {
                            throw new Error("Cross-signing key upload auth canceled");
                        }
                    },
                    setupNewCrossSigning: true,
                });

                // Now we can indicate that the user is done pressing buttons, finally.
                // Upstream flows will detect the new secret storage, key backup, etc and use it.
                this.props.onFinished({});
            }, true);
        } catch (e) {
            logger.error(e);
            this.props.onFinished(false);
        }
    };

    /* CTalk hide un-used
    private getKeyValidationText(): string {
        if (this.state.recoveryKeyFileError) {
            return _t("encryption|access_secret_storage_dialog|key_validation_text|wrong_file_type");
        } else if (this.state.recoveryKeyCorrect) {
            return _t("encryption|access_secret_storage_dialog|key_validation_text|recovery_key_is_correct");
        } else if (this.state.recoveryKeyValid) {
            return _t("encryption|access_secret_storage_dialog|key_validation_text|wrong_security_key");
        } else if (this.state.recoveryKeyValid === null) {
            return "";
        } else {
            return _t("encryption|access_secret_storage_dialog|key_validation_text|invalid_security_key");
        }
    }
    */

    /**
     * CTalk added
     * @private
     */
    private validatePassCodeRules = withValidation({
        rules: [
            {
                key: "required",
                test({ value, allowEmpty }): boolean {
                    return allowEmpty || !!value;
                },
                invalid: (): string => _t("ctalk|error|enter_pass_code")
            },
            {
                key: "length",
                test: ({ value }): boolean => !value || value.length > 5,
                invalid: (): string => _t("CTALK_MINIMUM_PASS_CODE"),
            },
        ],
    });

    /**
     * CTalk added
     * @private
     */
    private onPassCodeValidate = (fieldState): IValidationResult => {
        return this.validatePassCodeRules(fieldState);
    };

    /**
     * CTalk added
     * @private
     */
    private onPassCodeChange = (ev: ChangeEvent<HTMLInputElement>): void => {
        if (/^[0-9\b]+$/.test(ev.target.value) || ev.target.value === "") {
            this.setState({
                passCode: ev.target.value,
                textErrorCloudKit: "",
                recoveryKeyValid: ev.target.value.length > 0,
            });
        }
    };

    /**
     * CTalk added
     * @private
     */
    public cloudKitSetUpAuth(): void {
        CloudKitService.setUpAuth({
            onFinished: () => {
                this.setState({
                    loading: false,
                });
            },
            onAuthenticated: () => {
                if (this.waitingLoginModal) {
                    this.waitingLoginModal?.close();
                    this.waitingLoginModal = null;
                }
                this.setState({
                    isAppleLoggedIn: true,
                });
                this.fetchCloudKitData();
            }
        }).catch((e) => {
            this.setState({
                syncCloudKitError: true,
                textErrorCloudKit: e.message,
            })
        }).finally(() => {
            this.setState({
                loading: false,
            });
        });
    }

    private async fetchCloudKitData(): Promise<void> {
        try {
            const cli = MatrixClientPeg.safeGet();
            const userId = cli.getUserId();
            const res = await CloudKitService.fetchKeyDecrypted(
                userId,
                {
                    zoneID: this.cloudKitConfig.zone_name,
                },
            );
            this.setState({
                keyEncrypted: res.key,
                textErrorCloudKit: "",
            });
        } catch (err: ICloudKitError) {
            let textErrorCloudKit = _t("CTALK_CANNOT_SYNC_DATA");
            if (err.ckErrorCode === "NOT_FOUND") {
                textErrorCloudKit = _t('CTALK_KEY_NOT_STORE_ON_CLOUDKIT');
            }
            this.setState({
                textErrorCloudKit,
                syncCloudKitError: true,
            });
        }
    }

    /**
     * CTalk added
     * @private
     */
    private onAppleSignIn(): void {
        this.waitingLoginModal = Modal.createDialog(WaitingLoginDialog, {
            title: _t("auth|sso_complete_in_browser_dialog_title"),
            description: <Spinner />,
            onFinished: async () => {
                if (!this.state.isAppleLoggedIn) {
                    this.setState({
                        loading: true,
                        loginType: ELoginType.CLOUDKIT,
                    });
                    this.cloudKitSetUpAuth();
                }
            },
        });
    }

    /**
     * CTalk added
     * @private
     */
    private renderButtonContainersHTML(): React.ReactNode {
        // noinspection CheckTagEmptyBody
        return (
            <div className="ctalk_appleLoginContainer">
                <div
                    id="apple-sign-in-button"
                    onClick={(): void => this.onAppleSignIn()}
                />
                <div
                    style={{ display: "none" }}
                    id="apple-sign-out-button"
                />
            </div>
        );
    }

    /**
     * CTalk added
     * @private
     */
    private getContentBackupType(): React.ReactNode {
        if (this.state.loginType === ELoginType.CLOUDKIT) {
            const appleSignButtons = this.renderButtonContainersHTML();
            return (
                <div className="ctalk_CloudKit_login">
                    <div>{!this.state.isAppleLoggedIn && appleSignButtons}</div>
                    <div>
                        {
                            this.state.isAppleLoggedIn && !this.state.loading && !this.state.syncCloudKitError &&
                            (
                                <div className="ctalk_boxRecoverKey">
                                    <Field
                                        id="mx_passPhraseInput"
                                        className="mx_AccessSecretStorageDialog_passPhraseInput"
                                        type="password"
                                        label={_t("CTALK_USING_PASS_CODE_RESTORE")}
                                        value={this.state.passCode}
                                        onChange={this.onPassCodeChange}
                                        forceValidity={this.state.keyMatches}
                                        autoFocus={true}
                                        autoComplete="new-password"
                                        onValidate={this.onPassCodeValidate}
                                        ref={(field): void =>
                                            (this[InputField.PassCode] = field)
                                        }
                                    />
                                </div>
                            )
                        }
                        {
                            this.state.textErrorCloudKit && (
                                <div className="text-error">{this.state.textErrorCloudKit}</div>
                            )
                        }
                    </div>
                </div>
            );
        }
        return (
            <div>
                <p>{ _t("encryption|access_secret_storage_dialog|use_security_key_prompt") }</p>
                <div className="mx_AccessSecretStorageDialog_recoveryKeyEntry ctalk_boxRecoverKey">
                    <div className="mx_AccessSecretStorageDialog_recoveryKeyEntry_textInput ctalk_inputRecoverKey">
                        <Field
                            type="password"
                            id="mx_securityKey"
                            label={_t("encryption|access_secret_storage_dialog|security_key_title")}
                            value={this.state.recoveryKey}
                            onChange={this.onRecoveryKeyChange}
                            forceValidity={this.state.recoveryKeyCorrect ?? undefined}
                            autoComplete="off"
                        />
                    </div>
                    <span className="mx_AccessSecretStorageDialog_recoveryKeyEntry_entryControlSeparatorText">
                        {_t("encryption|access_secret_storage_dialog|separator", {
                            recoveryFile: "",
                            securityKey: "",
                        })}
                    </span>
                    <div>
                        <input
                            type="file"
                            hidden
                            className="mx_AccessSecretStorageDialog_recoveryKeyEntry_fileInput"
                            ref={this.fileUpload}
                            onClick={chromeFileInputFix}
                            onChange={this.onRecoveryKeyFileChange}
                        />
                        <AccessibleButton kind="primary" onClick={this.onRecoveryKeyFileUploadClick}>
                            {_t("action|upload")}
                        </AccessibleButton>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * CTalk added
     * @private
     */
    private onUploadChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            loginType: ELoginType.SECURITY_KEY,
            loading: false,
        });
    };

    /**
     * CTalk added
     * @private
     */
    private onCloudKitChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            loginType: ELoginType.CLOUDKIT,
        });
        if (!this.state.isAppleLoggedIn) {
            this.setState({
                loading: true,
            });
            this.cloudKitSetUpAuth();
        }
    };

    public render(): React.ReactNode {
        const hasPassphrase = this.props.keyInfo?.passphrase?.salt && this.props.keyInfo?.passphrase?.iterations;

        const resetButton = (
            <div className="mx_AccessSecretStorageDialog_reset">
                {_t("encryption|reset_all_button", undefined, {
                    a: (sub) => ( // CTalk added <br/>
                        <>
                            <br />
                            <AccessibleButton
                                kind="link_inline"
                                onClick={this.onResetAllClick}
                                className="mx_AccessSecretStorageDialog_reset_link"
                            >
                                {sub}
                            </AccessibleButton>
                        </>
                    ),
                })}
            </div>
        );

        let content;
        let title;
        let titleClass;
        if (this.state.resetting) {
            title = _t("encryption|access_secret_storage_dialog|reset_title");
            titleClass = ["mx_AccessSecretStorageDialog_titleWithIcon mx_AccessSecretStorageDialog_resetBadge"];
            content = (
                <div>
                    <p>{_t("encryption|access_secret_storage_dialog|reset_warning_1")}</p>
                    <p>{_t("encryption|access_secret_storage_dialog|reset_warning_2")}</p>
                    <DialogButtons
                        primaryButton={_t("action|reset")}
                        onPrimaryButtonClick={this.onConfirmResetAllClick}
                        hasCancel={true}
                        onCancel={this.onCancel}
                        focus={false}
                        primaryButtonClass="danger"
                    />
                </div>
            );
        } else if (hasPassphrase && !this.state.forceRecoveryKey) {
            title = _t("encryption|access_secret_storage_dialog|security_phrase_title");
            titleClass = ["mx_AccessSecretStorageDialog_titleWithIcon mx_AccessSecretStorageDialog_securePhraseTitle"];

            let keyStatus;
            if (this.state.keyMatches === false) {
                keyStatus = (
                    <div className="mx_AccessSecretStorageDialog_keyStatus">
                        {"\uD83D\uDC4E "}
                        {_t("encryption|access_secret_storage_dialog|security_phrase_incorrect_error")}
                    </div>
                );
            } else {
                keyStatus = <div className="mx_AccessSecretStorageDialog_keyStatus" />;
            }

            content = (
                <div>
                    <p>
                        {_t(
                            "encryption|access_secret_storage_dialog|enter_phrase_or_key_prompt",
                            {},
                            {
                                button: (s) => (
                                    <AccessibleButton kind="link_inline" onClick={this.onUseRecoveryKeyClick}>
                                        {s}
                                    </AccessibleButton>
                                ),
                            },
                        )}
                    </p>

                    <form className="mx_AccessSecretStorageDialog_primaryContainer" onSubmit={this.onPassPhraseNext}>
                        <Field
                            inputRef={this.inputRef}
                            id="mx_passPhraseInput"
                            className="mx_AccessSecretStorageDialog_passPhraseInput"
                            type="password"
                            label={_t("encryption|access_secret_storage_dialog|security_phrase_title")}
                            value={this.state.passPhrase}
                            onChange={this.onPassPhraseChange}
                            autoFocus={true}
                            autoComplete="new-password"
                        />
                        {keyStatus}
                        <DialogButtons
                            primaryButton={_t("action|continue")}
                            onPrimaryButtonClick={this.onPassPhraseNext}
                            hasCancel={true}
                            onCancel={this.onCancel}
                            focus={false}
                            primaryDisabled={this.state.passPhrase.length === 0}
                            additive={resetButton}
                        />
                    </form>
                </div>
            );
        } else {
            title = _t("encryption|access_secret_storage_dialog|security_key_title");
            titleClass = ["mx_AccessSecretStorageDialog_titleWithIcon mx_AccessSecretStorageDialog_secureBackupTitle"];
            /* CTalk hide this
            const feedbackClasses = classNames({
                "mx_AccessSecretStorageDialog_recoveryKeyFeedback": true,
                "mx_AccessSecretStorageDialog_recoveryKeyFeedback--valid": this.state.recoveryKeyCorrect === true,
                "mx_AccessSecretStorageDialog_recoveryKeyFeedback--invalid": this.state.recoveryKeyCorrect === false,
            });
            const recoveryKeyFeedback = <div className={feedbackClasses}>{this.getKeyValidationText()}</div>;
            */
            const contentBackupType = this.getContentBackupType(); // CTalk added
            content = (
                <div>
                    {/* <p>{_t("encryption|access_secret_storage_dialog|use_security_key_prompt")}</p> CTalk hide this */}

                    <form
                        className="mx_AccessSecretStorageDialog_primaryContainer"
                        onSubmit={this.onRecoveryKeyNext}
                        spellCheck={false}
                        autoComplete="off"
                    >
                        <div className="mx_AccessSecretStorageDialog_recoveryKeyEntry">
                            {/* CTalk start changes */}
                            <div className="ctalk_AccessSecretStorageDialog_recoveryKeyBody">
                                <div className="mx_CompleteSecurity_body">
                                    <div>
                                        {_t("encryption|access_secret_storage_dialog|use_security_key_prompt",)}
                                    </div>
                                </div>
                                <div className="ctalk_typeLoginContainer">
                                    <label>
                                        <StyledRadioButton
                                            name="image_size"
                                            checked={
                                                this.state.loginType === ELoginType.SECURITY_KEY
                                            }
                                            onChange={this.onUploadChange}
                                        >
                                            { _t("CTALK_MANUAL_UPLOAD_KEY") }
                                        </StyledRadioButton>
                                    </label>
                                    <label>
                                        <StyledRadioButton
                                            name="image_size"
                                            checked={
                                                this.state.loginType === ELoginType.CLOUDKIT
                                            }
                                            onChange={this.onCloudKitChange}
                                        >
                                            { _t("CTALK_RESTORE_KEY_CLOUDKIT") }
                                        </StyledRadioButton>
                                    </label>
                                </div>
                                <div>{ this.state.loading && <Spinner /> }</div>
                               {contentBackupType}
                            </div>
                            { /* recoveryKeyFeedback */ }
                            <DialogButtons
                                primaryButton={_t("action|continue")}
                                onPrimaryButtonClick={this.onRecoveryKeyNext}
                                hasCancel={true}
                                cancelButton={_t("action|go_back")}
                                cancelButtonClass="outline"
                                onCancel={this.onCancel}
                                focus={false}
                                primaryDisabled={!this.state.recoveryKeyValid}
                                additive={resetButton}
                            />
                        </div>
                    </form>
                </div>
            );
        }

        return (
            <BaseDialog
                className="mx_AccessSecretStorageDialog"
                onFinished={this.props.onFinished}
                title={title}
                titleClass={titleClass}
            >
                <div>{content}</div>
            </BaseDialog>
        );
    }
}

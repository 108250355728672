
import { MatrixEvent } from "matrix-js-sdk/src/models/event";
import * as utils from "matrix-js-sdk/src/utils";
import { Method } from "matrix-js-sdk/src/http-api/index";
import { logger } from 'matrix-js-sdk/src/logger';
import { ClientEvent } from "matrix-js-sdk/src/client";
import { AsyncStoreWithClient } from "matrix-react-sdk/src/stores/AsyncStoreWithClient";
import { ActionPayload } from "matrix-react-sdk/src/dispatcher/payloads";
import defaultDispatcher from "matrix-react-sdk/src/dispatcher/dispatcher";
import Modal from "matrix-react-sdk/src/Modal";
import dis from "matrix-react-sdk/src/dispatcher/dispatcher";
import SdkConfig from "matrix-react-sdk/src/SdkConfig";
import { MatrixClientPeg } from "matrix-react-sdk/src/MatrixClientPeg";
import { LocalStorageKey } from "@ctalk/constants";
import { UserEvent } from "@ctalk/enums/user-event.enum";
import { MultiFactorState } from "@ctalk/interfaces/auth/MultiFactorState";
import { GetMultiFactorRO, IUserProfile } from '@ctalk/interfaces/stores/IUserProfile';
import { EPlatform } from "@ctalk/enums/user-settings.enum";
import { getPlatform } from "@ctalk/helpers/PlatformHelper";
import CTalkErrorDialog from "@ctalk/components/views/dialogs/CTalkErrorDialog";
import { clearStorageIntegrationsWidgets } from "@ctalk/helpers/WidgetHelper";
import { IRetryCheckingPlatform } from '@ctalk/interfaces/settings/IGlobalSettings';
import { AppSettings, SettingStoreEvent } from '@ctalk/enums/setting-store.enum';
import { CTalkClient } from '@ctalk/CTalkClient';
import { CTalkClientPeg } from '@ctalk/CTalkClientPeg';
import { SdkContextClass } from "matrix-react-sdk/src/contexts/SDKContext";
import { Action } from "matrix-react-sdk/src/dispatcher/actions";

import { setDeviceSettings } from '../utils/DeviceSettings';
import { closeAllModals } from "../ctalk/helpers/ModalHelper";
import { forceHangUpOrRejectLegacyCall, forceLeaveRoomDeleted } from "../ctalk/helpers/RoomHelper";
import { _t } from "../languageHandler";

interface IState {
    isSynapseAdmin: boolean
    username?: string | null;
    multiFactorState: MultiFactorState;
    platforms?: EPlatform[];
    configRetryCheckPlatform: IRetryCheckingPlatform;
}
export const DEFAULT_MULTI_FACTOR_STATE = {
    enabledTwoFactor: false,
    requiredTwoFactor: false,
    enabledYubikey: false,
    requiredYubikey: false,
};

const DEFAULT_CONFIG_CHECK_PLATFORM: IRetryCheckingPlatform = {
    numberOfRetry: SdkConfig.get<any>('setting_defaults')['UserPlatform.numberOfRetry'] ?? 3,
    periodRetry: SdkConfig.get<any>('setting_defaults')['UserPlatform.periodRetry'] ?? 300, // Seconds
};

export class UserProfileStore extends AsyncStoreWithClient<IState> {
    protected ckClient: CTalkClient;

    private static readonly internalInstance = ((): UserProfileStore => {
        const instance = new UserProfileStore();
        instance.start();
        return instance;
    })();

    public constructor() {
        super(defaultDispatcher, {
            isSynapseAdmin: false,
            username: localStorage.getItem(LocalStorageKey.USERNAME),
            multiFactorState:
                JSON.parse(localStorage.getItem(LocalStorageKey.MULTI_FACTOR_STATE) ?? '{}') || DEFAULT_MULTI_FACTOR_STATE,
            platforms: [],
            configRetryCheckPlatform: DEFAULT_CONFIG_CHECK_PLATFORM,
        });
        this.ckClient = CTalkClientPeg.get();
    }

    public static get instance(): UserProfileStore {
        return UserProfileStore.internalInstance;
    }

    public get isSynapseAdmin(): boolean {
        return this.state.isSynapseAdmin;
    }

    public get username(): string | null | undefined{
        if (!this.matrixClient) return this.state.username || null;

        if (this.matrixClient.isGuest()) {
            return undefined;
        }
        if (this.state.username) {
            return this.state.username;
        }
        return undefined;
    }

    public setAuthUrl(url: string): void {
        this.ckClient.setUrl(url);
    }

    public setUsername(username: string): Promise<void> {
        window.localStorage.setItem(LocalStorageKey.USERNAME, username);
        return this.updateState({
            username: username,
        });
    }

    public async checkMultiFactorState(): Promise<GetMultiFactorRO | void> {
        const state = await this.ckClient
            .getMultiFactorStatus()
            .catch((e) => {
                logger.error(e);
                return;
            });
        if (state) {
            this.updateState({
                multiFactorState: state,
            });
        }
        return state;
    }

    public get multiFactorState(): MultiFactorState {
        if (!this.matrixClient) return this.state.multiFactorState;

        if (this.matrixClient.isGuest()) {
            return DEFAULT_MULTI_FACTOR_STATE;
        }
        return this.state.multiFactorState;
    }

    public setMultiFactor(newData: MultiFactorState): void {
        const oldData = JSON.parse(window.localStorage.getItem(LocalStorageKey.MULTI_FACTOR_STATE) || '{}');
        const dataUpdate = { ...oldData, ...newData };
        window.localStorage.setItem(LocalStorageKey.MULTI_FACTOR_STATE, JSON.stringify(dataUpdate));
        this.updateState({
            multiFactorState: dataUpdate,
        });
    }

    public setTwoFactor(enabled: boolean): void {
        const state = this.state.multiFactorState;
        state.enabledTwoFactor = enabled;
        this.updateState({
            multiFactorState: state,
            username: undefined,
        })
    }

    public setYubiKey(enabled: boolean): void {
        const state = this.state.multiFactorState;
        state.enabledYubikey = enabled;
        this.updateState({
            multiFactorState: state,
        })
    }

    public get platforms(): EPlatform[] | undefined{
        if (!this.matrixClient) return this.state.platforms;

        if (this.matrixClient.isGuest()) {
            return [];
        }
        if (this.state.platforms) {
            return this.state.platforms;
        }
        return [];
    }

    public setPlatforms(platforms: EPlatform[]): void {
        this.updateState({
            platforms: platforms,
        });
    }

    protected async onNotReady(): Promise<void> {
        this.matrixClient?.removeListener(ClientEvent.ToDeviceEvent, this.onToDeviceEvent);
        await this.reset({
            isSynapseAdmin: false,
            username: undefined,
            multiFactorState: DEFAULT_MULTI_FACTOR_STATE,
            platforms: [],
        });
    }

    /**
     * Override MatrixClient.isSynapseAdmin() method, using 'v2' instead of 'v1'
     * @protected
     */
    protected fetchIsSynapseAdmin(): Promise<boolean> | undefined {
        const cli = this.matrixClient;
        const path = utils.encodeUri("/_synapse/admin/v1/users/$userId/admin", { $userId: cli?.getUserId() });
        return cli?.http.authedRequest<{ admin: boolean }>(Method.Get, path, undefined, undefined, { prefix: "" })
            .then((r) => r.admin)
            .catch(() => false);
    }

    protected async onReady(): Promise<void> {
        this.matrixClient?.on(ClientEvent.ToDeviceEvent, this.onToDeviceEvent);
        const isSynapseAdmin = await this.fetchIsSynapseAdmin();
        const username = localStorage.getItem(LocalStorageKey.USERNAME);
        const state: MultiFactorState = JSON.parse(
            localStorage.getItem(LocalStorageKey.MULTI_FACTOR_STATE) || '{}'
        );
        this.checkMultiFactorState();
        this.onUpdateUserProfile({
            username: username!,
            enabledTwoFactor: state.enabledTwoFactor,
            enabledYubikey: state.enabledYubikey,
        });
        this.updateState({
            isSynapseAdmin: isSynapseAdmin,
        })
    }

    protected async onAction(payload: ActionPayload): Promise<void> {
        // We don't actually do anything here
    }

    private onUpdateUserProfile = async (payload: IUserProfile): Promise<void> => {
        let username = this.state.username;
        const state: MultiFactorState = this.state.multiFactorState;
        let platforms = this.state.platforms;
        let isSynapseAdmin = this.state.isSynapseAdmin;
        if (payload) {
            if (typeof payload.username !== 'undefined') {
                if (payload.username) {
                    username = payload.username;
                    window.localStorage.setItem(LocalStorageKey.USERNAME, username);
                } else {
                    username = undefined;
                    window.localStorage.removeItem(LocalStorageKey.USERNAME);
                }
            }

            if (typeof payload?.enabledTwoFactor !== 'undefined') {
                state.enabledTwoFactor = payload.enabledTwoFactor;
            }

            if (typeof payload?.enabledYubikey !== 'undefined') {
                state.enabledYubikey = payload.enabledYubikey;
            }

            if (typeof payload?.requiredYubikey !== 'undefined') {
                state.requiredYubikey = payload.requiredYubikey;
            }

            if (typeof payload?.platforms !== 'undefined') {
                platforms = payload.platforms;
            }

            if (typeof payload?.isSynapseAdmin !== 'undefined') {
                isSynapseAdmin = payload.isSynapseAdmin;
            }
        }

        window.localStorage.setItem(LocalStorageKey.MULTI_FACTOR_STATE, JSON.stringify(state));

        await this.updateState({
            username: username,
            multiFactorState: state,
            platforms: platforms,
            isSynapseAdmin: isSynapseAdmin,
        });
    };

    private onToDeviceEvent = async (ev: MatrixEvent): Promise<void> => {
        if (
            Object.values(UserEvent).includes(ev.getType() as UserEvent)
        ) {
            if (ev.getType() === UserEvent.Platforms) {
                this.onChangePlatforms(ev.getContent().platforms);
            }
            if (ev.getType() === UserEvent.DeleteRoom) {
                this.onEventDeletedRoom(ev);
            }
            await this.onUpdateUserProfile(ev.getContent());
            this.emit(ev.getType());
        }
    };

    private async onChangePlatforms(allowPlatforms?: EPlatform[]): Promise<void> {
        const currentPlatform = getPlatform();
        if (!allowPlatforms?.includes(currentPlatform)) {
            // Force logout if the platform's user is not allowed
            Modal.createDialog(
                CTalkErrorDialog,
                {
                    title: _t("common|error"),
                    description: _t("ctalk|error|platform_not_allow", { appName: SdkConfig.get("brand") }),
                },
                undefined,
                true,
            );
            dis.dispatch({ action: 'logout' });
            clearStorageIntegrationsWidgets();
            return;
        }
        await setDeviceSettings(SettingStoreEvent.isCheckPlatformFailed, false);
    }

    private async onEventDeletedRoom(ev: MatrixEvent): Promise<void> {
        const { room_id: roomId } = ev.getContent();
        if (!roomId) {
            return;
        }

        const currentRoomId = SdkContextClass.instance.roomViewStore.getRoomId();
        if (currentRoomId === roomId) {
            closeAllModals('ctalk_dialog_warning_deleted_room');
            dis.dispatch({ action: Action.ViewHomePage });
        }

        const cli = MatrixClientPeg.safeGet();

        // Handle reject or hang up legacy call
        forceHangUpOrRejectLegacyCall(roomId);

        // Handle leave room on local with deleted room
        await forceLeaveRoomDeleted(cli, roomId);
    }

    public async checkUserPlatform(isRetry: boolean, numberOfRetry = 0): Promise<void> {
        try {
            const res = await this.ckClient.getUserSettings();
            await this.onChangePlatforms(res.platforms);
            this.setPlatforms(res.platforms);
        } catch (e) {
            console.log(e);
            if (!isRetry) {
                return;
            }
            ++numberOfRetry;
            if (numberOfRetry === 1) { // First retry
                await this.fetchGlobalSettings();
            }
            if (numberOfRetry <= this.state.configRetryCheckPlatform.numberOfRetry) {
                setTimeout(() => {
                    this.checkUserPlatform(true, numberOfRetry);
                }, this.state.configRetryCheckPlatform.periodRetry * 1000);
            } else {
                this.emit(UserEvent.IsCheckPlatformFailed);
            }
        }
    }

    protected async fetchGlobalSettings(): Promise<void> {
        const settings = await this.ckClient.getGlobalSettings().catch(() => { return null; });
        if (settings) {
            const numberOfRetry = settings.find((i: any) => i.key === AppSettings.PLATFORM_NUMBER_OF_RETRY_CHECKS)?.value;
            const periodRetry = settings.find((i: any) => i.key === AppSettings.PLATFORM_PERIOD_RETRY_CHECKS)?.value;
            if (numberOfRetry && periodRetry) {
                await this.updateState({
                    configRetryCheckPlatform: {
                        numberOfRetry: Number(numberOfRetry),
                        periodRetry: Number(periodRetry),
                    },
                });
            }
        }
    }
}

import EditorModel from "matrix-react-sdk/src/editor/model";
import { MatrixClient, Room } from "matrix-js-sdk/src/matrix";
import { CommandPartCreator } from "matrix-react-sdk/src/editor/parts";

import { Part } from "../../editor/parts";

export function createMockEditorModel(mxClient: MatrixClient, parts: Part[] = [], room?: Room): EditorModel {
    const fakeRoom: any = room ?? {};
    const partCreator = new CommandPartCreator(fakeRoom, mxClient);

    return new EditorModel(parts, partCreator);
}
